import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import 'core-js/stable';

import Layout from '../components/Layout';
import PageTransition from '../components/PageTransition';
import { Content, Locales } from '../types/QueryTypes';

interface DataProps {
  locales: Locales;
  homeAssets: {
    nodes: [
      {
        id: string;
        slug: string;
        title: string;
        content: Content;
      }
    ];
  };
}

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    homeAssets: allContent(filter: { title: { eq: "home" } }) {
      nodes {
        title
        content {
          assets {
            title
            year
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default function IndexPage({ data }: PageProps<DataProps>) {
  const image = data.homeAssets.nodes[0].content.assets?.[0].image;
  const imageData = image?.childImageSharp?.gatsbyImageData;

  return (
    <div tw="overflow-y-hidden">
      <PageTransition>
        <BgImage image={imageData} preserveStackingContext>
          <div style={{ minHeight: '100vh' }} tw="overflow-hidden">
            <Layout landing>
              <div tw="bg-transparent " />
            </Layout>
          </div>
        </BgImage>
      </PageTransition>
    </div>
  );
}
